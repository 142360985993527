.feedback h2 {
    font-family: $Montserrat;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
    letter-spacing: 0px;

    margin: 0;
    margin-bottom: 30px;
}

.feedback h2 span {
    color: $mainColor;
}

@media (min-width: $desktop-width) {
    .feedback h2 {
        margin-bottom: 20px;
    }
}