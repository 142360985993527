.comment {
    text-align: center;
}

.comment__avatar {
    display: block;
    margin: 0 auto;
    margin-bottom: 19px;
}

.comment__information {
    font-family: $Lora;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    color: #000000;

    display: inline-block;
    vertical-align: top;

    margin-bottom: 7px;
}

.comment__position {
    font-family: $Lora;
    font-weight: 400;
    font-size: 14px;
    font-weight: 500;
    color: #777777;

    margin-top: 0;
    margin-bottom: 25px;
}

.comment__description {
    font-family: $Montserrat;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-align: left;

    margin: 0;
    padding: 40px 16px;
    border: 5px solid $mainColor;
    border-radius: 15px;
}

@media (min-width: $desktop-width) {
    .comment__avatar {
        margin-bottom: 12px;
    }

    .comment__position {
        margin-bottom: 54px;
    }

    .comment__description {
        font-size: 24px;

        width: 945px;
        margin: 0 auto;

        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 55px;
        padding-top: 55px;
    }
}