.main__about {
    margin-bottom: 27px;
}

.main__advantages {
    margin-bottom: 26px;
}

.main__services {
    margin-bottom: 33px;
}

.main__feedback {
    margin-bottom: 64px;
}

@media (min-width: $desktop-width) {
    .main__intro {
        margin-top: -138px;
    }

    .main__about {
        margin-bottom: 115px;
    }

    .main__advantages {
        margin-bottom: 0;
    }

    .main__services {
        margin-bottom: 90px;
    }

    .main__feedback {
        margin-bottom: 118px;
    }
}