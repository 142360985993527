.nav--nojs,
.nav--opened {
    display: block;
}

.nav--opened + .header__top-wrapper .header__toggle::before {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../img/icon-close.svg");
}

.nav--closed {
    display: none;
}

.nav {
    background-color: $mainColor;
    text-align: center;
}

.nav__item {
    font-family: $Montserrat;
    font-weight: 700;
    font-size: 20px;
    color: #111111;
    list-style-type: none;
    text-transform: uppercase;

    padding-top: 20px;
    padding-bottom: 21px;
    border-bottom: 1px solid #000;
}

.nav__item a {
    color: inherit;
}

// .nav__list {
//     padding-left: 0;
//     padding-right: 0;
// }

.nav__item:last-child {
    border-bottom: none;
}

@media (min-width: $desktop-width) {
    .nav--closed {
        display: block;
    }

    .nav__list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        width: auto;
    }

    .nav {
        background-color: transparent;
        width: 585px;
        padding-left: 20px;
    }

    .nav__item {
        font-family: $Lora;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        text-transform: none;
        border: none;

        padding-bottom: 8px;

        transition-property: color, border;
        transition-duration: 0.2s;
    }

    .nav__item:hover,
    .nav__item:last-child:hover {
        color: $mainColor;
        border-bottom: 2px solid $mainColor;

        cursor: pointer;
    }
}