.logo {
    font-family: "Lora", serif;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    text-align: center;

    display: block;
}

@media (min-width: $desktop-width) {
    .logo {
        font-size: 38px;
    }
}