.services h2 {
    font-family: $Montserrat;
    font-size: 32px;
    font-weight: 500;
    color: #000000;
    letter-spacing: 1px;

    display: block;
    width: 240px;
    margin: 0 auto;
    margin-bottom: 35px;


    position: relative;

}

.services h2::before {
    content: '';

    position: absolute;
    bottom: -11px;
    left: 50%;

    width: 94%;
    height: 2px;
    margin: 0 auto;
    margin-left: -113px;

    background-color: $mainColor;
}

.services__wrapper {
    background-image: url("../img/services-background.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.services__list {
    padding-top: 1px;
    padding-bottom: 34px;
}

.services__item {
    margin: 0;
    padding: 0;
    min-height: 326px;
    margin-top: 20px;
    margin-bottom: 30px;

    background-color: #ffffff;
    border: 3px solid $mainColor;
    border-radius: 15px;

    list-style-type: none;

    // position: relative;
}

// .services__item::before {
//     content: '';

//     position: absolute;
//     top: 0;
//     left: 0;

//     width: 100%;
//     height: 100%;

//     z-index: 2;

//     background-color: #fff;
// }

.services__item b {
    font-family: $Montserrat;
    font-weight: 400;
    font-size: 26px;
    color: #000000;
    text-align: center;

    display: block;
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 45px;

    position: relative;
    z-index: 2;
}

.services__item b::before {
    content: '';

    position: absolute;
    bottom: -8px;
    left: 50%;

    width: 95%;
    height: 2px;
    margin: 0 auto;
    margin-left: -96px;

    background-color: $mainColor;
    z-index: 2;

}

.services__item:nth-child(2n) b::before {
    width: 67%;
    margin-left: -63px;
}

.services__description {
    font-family: $Montserrat;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    color: #7d7d7d;
    text-align: center;

    width: auto;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 32px;

    position: relative;
    z-index: 2;
}

.services__item:first-child .services__description {
    margin-bottom: 70px;
}

.services__contacts {
    font-family: $Montserrat;
    font-size: 12px;
    font-weight: 700;
    color: #000000;
    text-align: center;

    display: block;
    padding-left: 50px;
    padding-right: 50px;
}

.services__contacts a {
    color: inherit;

    display: block;
    width: 125px;
    margin: 0 auto;

    margin-bottom: 10px;
    margin-top: 5px;

    position: relative;
}

.services__contacts a::before {
    content: '';

    position: absolute;
    bottom: -4px;
    left: 50%;

    width: 95%;
    height: 2px;
    margin: 0 auto;
    margin-left: -56px;

    background-color: $mainColor;
}

.services__contacts a:first-child {
    margin-top: 4px;
    margin-bottom: 6px;
}

.services__hide-description .services_defenition {
    display: none;

    position: relative;
}

@media (min-width: $desktop-width) {
    .services {
        background-image: url("../img/services-background.jpg");
        background-position: top;
        background-size: cover;

        padding-top: 1px;
    }

    .services__wrapper {
        background-image: none;
    }

    .services h2 {
        margin-top: 139px;
        margin-bottom: 130px;
    }

    .services__item {
        width: 360px;
        min-height: 425px;

        position: relative;
    }

    .services__item b {
        font-size: 32px;

        width: 260px;
        margin-top: 33px;
        margin-bottom: 77px;
    }

    .services__item b::before {
        width: 100%;
        margin-left: -130px;
    }

    .services__item:nth-child(2n) b::before {
        width: 60%;
        margin-left: -78px;
    }

    .services__description {
        font-size: 18px;
        text-align: left;

        width: 320px;
    }

    .services__contacts {
        display: none;

        position: relative;
    }

    .services__item:first-child .services__description {
        margin-bottom: 78px;
    }

    .services__item:first-child {
        margin-bottom: 19px;
    }

    .services__item:nth-child(2n) {
        margin-left: auto;
        margin-right: 0;

        margin-top: 0;
        margin-bottom: 0;
    }

    .services__item:nth-child(2n) b {
        margin-bottom: 65px;
    }

    .services__item:nth-child(2n) .services__description {
        margin-bottom: 57px;
    }

    .services__item:nth-child(2n) .button--services::after {
        left: 70px;
    }

    .services__item:nth-child(3) .services__description {
        margin-bottom: 54px;
    }

    .services__hide-description {
        display: none;

        position: relative;

        animation: appearance_odd;
        animation-duration: 0.5s;
    }

    .services__item:nth-child(2n) .services__hide-description {
        animation: appearance_even;
        animation-duration: 0.5s;
    }

    .services .button--services:hover + .services__hide-description,
    .services .button--services:hover + .services__hide-description .services_defenition,
    .services .button--services:hover + .services__hide-description .services__contacts,
    .services__hide-description:hover  .services__contacts,
    .services__hide-description:hover .services_defenition {
        display: block;
    }

    .services .button--services:hover + .services__hide-description {
        display: block;
    }

    .services__hide-description:hover {
        display: block;
    }

    .services__hide-description {
        width: 855px;
        min-height: 100%;

        position: absolute;
        top: 0px;
        left: 280px;

        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;

        z-index: 1;
    }

    .services__item:nth-child(2n) .services__hide-description::before {
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
    }

    .services__item:nth-child(2n) .services__hide-description {
        left: auto;
        right: 280px;
    }

    .services__item .button--services::after {
        background-image: url("../img/arrow.png");
        transform: rotate(180deg);
    }

    .services__item:nth-child(2n) .button--services::after {
        background-image: url("../img/arrow.png");
        transform: rotate(0deg);
    }

    .services__hide-description .services_defenition {
        font-family: $Montserrat;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: right;

        width: 515px;
        margin-top: 100px;
        margin-bottom: 60px;
        margin-left: auto;
        margin-right: 60px;
    }

    .services__contacts {
        font-size: 14px;

        width: 270px;
        text-align: right;
        margin-right: 60px;
        margin-left: auto;
        padding-right: 0;
    }

    .services__contacts a {
        width: 130px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .services__contacts .phone {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 10px;
    }


    .services__item:nth-child(2n) .services__hide-description .services_defenition {
        margin-left: 60px;
        margin-right: auto;
        text-align: left;
    }

    .services__item:nth-child(2n) .services__hide-description .services__contacts {
        text-align: right;
        margin-right: auto;
        margin-left: 60px;
        padding-left: 0;

        width: 210px;
    }

    .services__hide-description::before {
        content: "";

        position: absolute;
        top: 0;
        right: 2px;

        width: 785px;
        height: 100%;

        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 0 0 3px $mainColor;

        border-left: none;

        background-position: -10px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .services__item:nth-child(n) .services__hide-description::before {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .services__item:nth-child(2n) .services__hide-description::before {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        left: 3px;
    }

    .services__item:nth-child(1) .services__hide-description::before {
        background-image: url('../img/service-child.png');
        background-position: right;
    }

    .services__item:nth-child(2) .services__hide-description::before {
        background-image: url('../img/service-weekend.png');
        background-position: -10px;
    }

    .services__item:nth-child(3) .services__hide-description::before {
        background-image: url('../img/service-comm.png');
    }

    .services__item:nth-child(4) .services__hide-description::before {
        background-image: url('../img/service-towns.png');
    }
}

@keyframes appearance_odd {
    from {
        opacity: 0;
        left: -68px;
    }

    50% {
        opacity: 0.30;
    }

    to {
        opacity: 1;
        right: 2px;
    }
}

@keyframes appearance_even {
    from {
        opacity: 0;
        right: -67px
    }

    50% {
        opacity: 0.30;
    }

    to {
        opacity: 1;
        right: 280px;
    }
}