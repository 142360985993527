  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
  }

  .visually-hidden {
    position: absolute;

    margin: -1px;
    padding: 0;

    width: 1px;
    height: 1px;
    border: 0;

    clip: rect(0 0 0 0);
    overflow: hidden;
  }