.intro {
    text-align: center;

    min-height: 295px;
    background-color: #2b2b2b;
    background-image: url("../img/main-mobile-background.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    padding-top: 1px;
}

.intro h1 {
    font-family: $Lora;
    font-weight: 400;
    font-size: 34px;
    line-height: 36px;
    color: #fff;

    width: 228px;
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 12px;
}

.intro h1 span {
    color: $mainColor;
}

.intro .button {
    border: none;
    margin-left: auto;
    margin-right: auto;

    background-color: $mainColor;
}

@media (min-width: $desktop-width) {
    .intro {
        text-align: left;
        min-height: 981px;

        background-image: url("../img/main-desktop-background.png"); //FIXME: другое фото
        background-position: top;
        background-size: cover;
    }

    .intro h1 {
        font-size: 100px;
        line-height: 1;

        width: 583px;
        margin: 0;
        margin-top: 173px;
        margin-bottom: 29px;
    }

    .intro .button {
        margin-left: 0;
    }

    .intro .button:hover {
        background-color: #000000;
    }
}