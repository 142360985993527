.button {
    font-family: $Lora;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    text-decoration: none;
    color: #000000;

    display: block;
    width: 160px;
    border-radius: 20px;
    background-color: $mainColor;

    padding: 10px;

    position: relative;

    z-index: 2;

    &--services {
        display: none;
    }
}

@media (min-width: $desktop-width) {
    .button {
            width: 350px;
            font-size: 18px;
            padding-top: 13px;
            padding-bottom: 13px;
            border-radius: 15px;

            transition-property: color, background-color;
            transition-duration: 0.3s;

            &--services {
                font-size: 12px;

                display: block;
                width: 225px;
                margin: 0 auto;
                padding-top: 9px;
                padding-bottom: 10px;

                margin-bottom: 56px;

                position: relative;
            }

            &--services::after {
                content: '';

                position: absolute;
                right: 67px;
                top: 13px;

                width: 4px;
                height: 7px;
            }
        }

        .button:hover {
            color: $mainColor;
            background-color: #000;
            box-shadow: 0 0 0 3px $mainColor;
        }
    }