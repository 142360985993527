.advantages {
    text-align: center;

    padding-top: 1px;

    background-color: #636363;
}

.advantages h2 {
    font-family: $Montserrat;
    font-size: 32px;
    color: #ffffff;
    line-height: 26px;

    margin-top: 23px;
}

.advantages h2 span {
    color: $mainColor;
}

.advantages__list {
    margin: 0;
    padding: 0;

    padding-bottom: 93px;
}

.advantages__item {
    list-style-type: none;

    position: relative;

    margin-top: 231px;
}

.advantages__item:nth-child(2) {
    margin-top: 162px;
}

.advantages__item:nth-child(3) {
    margin-top: 214px;
}

.advantages__item b {
    font-family: $Montserrat;
    font-weight: 700;
    font-size: 24px;
    line-height: 18px;
    color: $mainColor;

    display: block;
    width: 250px;
    margin: 0 auto;
    margin-bottom: 21px;

    position: relative;

    // border-bottom: 2px solid $mainColor;
}

.advantages__item b::after {
    content: '';

    position: absolute;
    bottom: -9px;
    left: 0;

    width: 100%;
    height: 2px;

    background-color: $mainColor;
}

.advantages__item p {
    font-family: $Montserrat;
    font-weight: 400;
    line-height: 18px;
    color: #ffffff;
    letter-spacing: -1px;

    margin: 0;
}

.advantages__item::before {
    content: '';

    position: absolute;

    width: 120px;
    height: 128px;

    left: 50%;
    top: -165px;
    margin-left: -60px;

    background-image: url("../img/car-driver.png");
}

.advantages__item:nth-child(2)::before {
    top: -133px;

    background-image: url("../img/seat-belt.png");
}

.advantages__item:nth-child(3)::before {
    top: -159px;

    background-image: url("../img/timer.png");
}

@media (min-width: $desktop-width) {
    .advantages h2 {
        font-weight: 500;
    }

    .advantages__list {
        display: flex;
        justify-content: space-between;

        padding-bottom: 84px;
    }

    .advantages__list .advantages__item {
        margin-top: 10px;
        // padding-left: 1px;
    }

    .advantages__item::before {
        left: 35px;
        top: 0;
    }

    .advantages__item:nth-child(2)::before {
        left: 30px;
        top: 7px;
    }

    .advantages__item:nth-child(3)::before {
        left: 46px;
        top: -1px;
    }

    .advantages__item {
        // margin-left: 10px;
        width: 350px;
        padding-left: 100px;
    }

    .advantages__item p {
        // width: 250px;
        margin-left: 0;
    }
}