.header {
    background-color: #2b2b2b;
    padding-bottom: 4px;

    display: flex;
    flex-direction: column;

    padding-left: 0;
    padding-right: 0;
}


.header__nav {
    order: 1;
}

.header__top-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    margin-bottom: 20px;
    padding-top: 20px;

    order: 0;
}


.header__toggle,
.header__phone {
    display: block;
    width: 30px;
    height: 23px;
    border: 0;
    margin-top: 8px;
    margin-left: 8px;

    cursor: pointer;

    position: relative;

    background-color: transparent;
}

.header__toggle::before,
.header__phone::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    width: 30px;
    height: 23px;
}

.header__toggle::before {
    background-image: url("../img/icon-mobile-menu.svg");
}

.header__phone::before {
    left: 6px;

    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../img/icon-phone.svg");
}

.header__contacts {
    display: none;
}

@media (min-width: $desktop-width) {
    .header__toggle,
    .header__phone {
        display: none;
    }

    .header {
        flex-direction: row;
        justify-content: space-between;
        padding-top: 47px;

        padding-left: 10px;
        padding-right: 10px;
    }

    .header__contacts {
        display: block;
        width: 235px;
        height: 60px;
        padding-right: 15px;

        border: 2px solid $mainColor;
        border-radius: 15px;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
        order: 3;

        position: relative;
    }

    .header__contacts .phone {
        font-family: $Lora;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
    }

    .header__contacts .phone:first-child {
        margin-top: 4px;
        margin-bottom: 10px;
    }

    .header__contacts::before {
        content: "";

        width: 30px;
        height: 32px;

        position: absolute;
        top: 10px;
        left: 15px;

        background-image: url("../img/icon-phone.svg"); //FIXME: SVG
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .header__top-wrapper {
        max-width: 165px;
        min-width: 165px;
        margin: 0;
        padding-left: 0;
        padding-top: 0;
    }
}