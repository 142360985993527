.wrapper {
    min-width: 320px;
    // width: 320px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: $desktop-width) {
    .wrapper {
        width: 1160px;
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 auto;

    }
}