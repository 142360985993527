.about {
    text-align: center;

    min-height: 164px;
    border-radius: 20px;
    margin-top: -22px;
    padding-top: 1px;

    background-color: $mainColor;
    box-shadow: 0 0px 35px rgba(0, 0, 0, 0.41);
}

.about h2 {
    font-family: $Montserrat;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;

    display: block;
    width: 240px;
    margin: 26px 20px 31px 20px;
    margin-left: auto;
    margin-right: auto;

    position: relative;
}

.about h2::after {
    content: '';

    position: absolute;
    bottom: -8px;
    left: 0;

    width: 100%;
    height: 2px;

    background-color: #000000;
}

.about__list {
    margin: 0;
    padding: 0;
}

.about__item {
    font-family: $Montserrat;
    font-weight: 800;
    font-size: 20px;
    line-height: 16px;
    list-style-type: none;
    text-transform: uppercase;
    letter-spacing: 0px;
}

.about__item:nth-child(2) {
    color: #ffffff;
}

.about__description {
    display: none;
}

@media (min-width: $desktop-width) {
    .about {
        min-height: 372px;
        margin-top: -187px;
    }

    .about h2 {
        margin-top: 57px;
    }

    .about h2:after {
        display: none;
    }

    .about__description {
        font-family: $Montserrat;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        text-align: left;

        display: block;
        width: 393px;
    }

    .about__list {
        text-align: right;
        position: relative;
    }

    .about__list::after {
        content: '';

        position: absolute;
        left: 0;
        bottom: -34px;

        width: 100%;
        height: 2px;

        background-color: #fff;
    }

    .about__item {
        font-size: 36px;
        line-height: 30px;
    }

    .about__information-wrapper {
        display: flex;
        width: 780px;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
    }
}