.contacts {
    width: 100%;
    min-height: 484px;
    padding-top: 1px;

    background-image: url("../img/map-mobile.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
}

.contacts h2 {
    font-family: $Montserrat;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin: 0;
    margin-top: 21px;
    margin-bottom: 24px;
}

.contacts__wrapper {
    min-height: 310px;
    margin: 0 auto;
    margin-top: 12px;
    border: 1px solid $mainColor;
    border-radius: 35px;

    background-color: #fff;

    box-shadow: 10px 0 35px rgba(0,0,0,.41);

    width: 275px;
    padding-left: 10px;
    padding-right: 10px;

    position: relative;
    z-index: 2;
}

.contacts__address {
    margin-bottom: 30px;
}

.contacts__whatsapp {
    display: block;
    margin: 0 auto;
    width: 19px;
    height: 19px;

    background-image: url("../img/whatsapp-logo.png");
    background-size: 19px;
    background-repeat: no-repeat;
}

.contacts__phone,
.contacts__email,
.contacts__address {
    font-family: $Montserrat;
    font-weight: 500;
    font-size: 12px;
    color: #7f7f7f;
}

.contacts__phone .phone,
.contacts__email span,
.contacts__address span{
    font-family: $Montserrat;
    font-weight: 500;
    font-size: inherit;
    color: #000000;
}

.contacts__phone,
.contacts__email,
.contacts__address {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.contacts__phone {
    margin-bottom: 51px;
}

.contacts__email {
    margin-top: 0;
    margin-bottom: 43px;
}

.contacts__phone .phone {
    display: block;
    width: 110px;
    margin-right: 0;
    margin-left: auto;
}

.contacts__phone .phone:first-child {
    margin-bottom: 12px;
}

.contacts__map {
    width: 100%;
    min-height: 484px;

    position: absolute;
    top: 0;
    left: 0;

    z-index: 1;

    border: none;
}

@media (min-width: $desktop-width) {
    .contacts {
        min-height: 905px;
        background-image: url("../img/map-desktop.png");
    }

    .contacts__wrapper {
        width: 458px;
        min-height: 521px;
        margin-top: 128px;

        margin-left: 0;
    }

    .contacts__wrapper h2 {
        font-size: 32px;
        margin-top: 40px;
        margin-bottom: 41px;
    }

    .contacts__phone,
    .contacts__email,
    .contacts__address {
        font-size: 18px;
    }

    .contacts__phone .phone {
        width: 196px;
    }

    .contacts__phone {
        margin-bottom: 84px;
    }

    .contacts__email {
        margin-bottom: 98px;
    }


    .contacts__address {
        margin-bottom: 60px;
    }

    .contacts__whatsapp {
        width: 32px;
        height: 32px;

        background-size: 32px;
    }

    .contacts__map {
        min-height: 905px;
    }
}