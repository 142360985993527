.footer {
    background-color: #000000;
    min-height: 52px;
    padding-top: 1px;
}

.footer__copyright {
    display: none;
}

.footer__logo {
    text-align: left;
}


.footer__wrapper {
    display: flex;
    justify-content: space-between;

    margin-top: 5px;

    position: relative;
}

.footer__phone-wrapper .phone {
    color: #ffffff;
    font-family: $Lora;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;

    display: block;
}

.footer__phone-wrapper .phone:first-child {
    margin-bottom: 15px;
}

.footer__wrapper::before {
    content: '';

    position: absolute;
    bottom: 17px;
    width: calc(100% - 40px);
    height: 1px;
    background-color: $mainColor;
}

@media (min-width: $desktop-width) {
    .footer {
        min-height: 120px;
    }

    .footer__logo {
        font-size: 18px;
        font-weight: 400;
    }

    .footer__wrapper {
        margin-top: 28px;
    }

    .footer__wrapper::before {
        bottom: 36px;
    }

    .footer__copyright {
        font-family: $Lora;
        font-size: 18px;
        color:#ffffff;

        display: block;
        margin-right: 10px;
    }

    .footer__phone-wrapper .phone {
        font-family: $Lora;
        font-weight: 400;
        font-size: 18px;
    }

    .footer__phone-wrapper {
        border: 2px solid $mainColor;
        border-radius: 15px;

        padding: 5px 30px;

        margin-right: -200px;
    }
}